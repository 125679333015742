import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiFunctions, postData, urlAPI } from "../../services/api.services";
import Snackbar from "@mui/material/Snackbar";

import { Alert, Box } from "@mui/material";
import { ClientSlider } from "../../components/commons/swiper";

import ellipse1 from '../../assets/images/Ellipse1.png'
import ellipse2 from '../../assets/images/Ellipse2.png'
import Marquee from "react-fast-marquee";

function Projects() {
  const [project, setProject] = useState([]);
  const [proSection, setProSection] = useState([]);
  const [brand, setBrand] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [toast, setToast] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });
  const { vertical, horizontal, open, message } = toast;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await postData("customer", formData);
    setToast((prev) => {
      return {
        ...prev,
        open: true,
        message: response.message ?? response.data.message,
      };
    });
    setFormData({ name: "", email: "", phone: "", message: "" });
  };
  const handleCloseToast = () => {
    setToast((prev) => {
      return {
        ...prev,
        open: false,
      };
    });
  };

  useEffect(() => {
    apiFunctions.project((data) => {
      setProject(data.data);
      setProSection(data.settings);
    });
    apiFunctions.brand((data) => {
      setBrand(data.data);
    });
  }, []);

  const renderTruncatedHTML = (htmlString, maxLength) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    let textContent = tempDiv.textContent || tempDiv.innerText || '';
    if (textContent.length > maxLength) {
      textContent = textContent.substr(0, maxLength) + '...';
    }
    tempDiv.innerHTML = textContent;
    return tempDiv.innerHTML;
  };

  return (
    <div id="project">
      {project.length >= 1 &&
      <>
        <div className='w-full h-screen relative'>
          <img
            className='w-full h-full object-cover'
            src={urlAPI+project[project.length - 1].image_url}
          />
          <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
          <div className="absolute w-full h-full top-0 left-0">
            <img src={ellipse1} alt="" />
          </div>
          <div className="absolute w-full h-full top-0 left-0">
            <img src={ellipse2} alt="" className="w-full h-screen" />
          </div>
          <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4 amiri-regular'>
            <h1 className='lg:text-5xl text-2xl tracking-widest font-semibold my-5 text-white"'>{project[project.length - 1].name}</h1>
            <div className='text-sm tracking-wider text-[#f1f1f1] leading-normal' dangerouslySetInnerHTML={{ __html: renderTruncatedHTML(project[project.length - 1].description, 100) }} />
            <a href={`/project/${project[project.length - 1].id}`}>
              <button className="mt-8 bg-transparent md:text-xl text-white border-white border-2 px-3 md:px-7 py-2 rounded-lg">
                Read More
              </button>
            </a>
          </div>
          
        </div>

        
        <section id="project-detail" className="bg-abu py-10 lg:py-40">
          <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto">
            <h1 className="text-4xl md:text-6xl font-medium text-center mb-5 text-birtua tracking-wider">
              {proSection.project_project_title}
            </h1>
            <div>
              {project.slice(0, -1).map((item, index) => (
                <div
                  key={index}
                  className={`lg:flex md:pt-28 ${
                    index % 2 === 0 ? "" : "md:flex-row-reverse"
                  }`}
                >
                  <div className="pt-10 md:pt-0 md:w-5/6 lg:w-3/6 m-auto">
                    <img
                      className="md:img-layout2 mx-auto lg:h-96 w-full object-cover rounded-xl"
                      src={urlAPI + item.image_url}
                      alt=""
                    />
                  </div>
                  <div
                    className={`m-auto px-5 text-center lg:text-left md:w-5/6 lg:w-3/6 my-auto pt-5 lg:pt-0 bg-clip-text text-transparent bg-gradient-to-r from-[#0E46A3] to-[#031736]  ${
                      index % 2 === 0
                        ? ""
                        : "lg:text-right md:from-[#031736] md:to-[#0E46A3]"
                    }`}
                  >
                    <h1 className="uppercase tracking-widest text-xl md:text-4xl font-bold">
                      {item.name}
                    </h1>
                    <div
                      className="py-5 text-black"
                      dangerouslySetInnerHTML={{ __html: renderTruncatedHTML(item.description,100) }}
                    />
                    <div className="pb-3 text-birmud">
                      <Link to={`/project/${item.id}`} className="uppercase underline underline-offset-4 hover:tracking-wider">read more</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        
      </>
      }

      <section id="behind-company" className="py-14 lg:py-20 border-b-[1px]">
        <div className="max-w-screen-xl mx-auto">
          <h1 className="uppercase text-3xl md:text-4xl lg:text-6xl text-center mb-10 text-birtua tracking-wider max-w-72 lg:max-w-xl m-auto">
            {proSection.project_client_title}
          </h1>
          {/* <ClientSlider data={brand} /> */}
          <Marquee direction="right" speed={100} delay={5}>
            {brand &&
              brand.map((item)=>
                <div className="mx-4">
                  <img src={urlAPI+item.image_url} alt={item.name} className="lg:grayscale hover:grayscale-0 h-10 w-24 md:h-14 md:w-32 lg:w-44 object-contain m-auto"/>
                </div>
              )
            }
          </Marquee>
        </div>
      </section>

      <section id="contact">
        <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg mx-auto my-20 lg:my-32">
          <h1 className="text-3xl tracking-wider text-birtua font-semibold pb-5">
            {proSection.project_title}
          </h1>
          <div className="text-base tracking-wide pb-5" dangerouslySetInnerHTML={{ __html: proSection.project_caption}}/>
          <hr className="mb-5" />
          <form className="max-w-sm" onSubmit={handleSubmit}>
            <div className="mb-5">
              <input
                type="text"
                id="fullname"
                name="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="mb-5">
              <input
                type="email"
                id="email"
                name="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-5">
              <input
                type="number"
                id="phone"
                name="phone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="mb-5">
              <input
                type="textarea"
                id="details"
                name="message"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Tell about your project"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="uppercase text-white px-10 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg"
            >
              {proSection.project_button_label}
            </button>
          </form>
        </div>
      </section>
      <Box sx={{ width: 500 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseToast}
        >
          <Alert
            onClose={handleCloseToast}
            severity={message.includes("500") ? "error" : "success"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
}

export default Projects;
