import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FloatingActionButtons from "../commons/buttons/BtnFloat";

const Index = ({ children }) => {
  return (
    <div className="w-full h-full">
      <Navbar />
      <main>{children}</main>
      <FloatingActionButtons/>
      <Footer />
    </div>
  );
};

export default Index;
