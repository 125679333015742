import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Layout from "./components/layouts";
import AboutUs from "./pages/AboutUs";
import Pricing from "./pages/Pricing";
import Project from "./pages/Project";
import Blog from "./pages/Blog";
import SinglePricing from "./pages/Pricing/SinglePricing";
import SingleNews from "./pages/Blog/SingleNews";
import SingleProject from "./pages/Project/SingleProject";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LandingPage}></Route>
          {/* <Route path="/admin/login" ></Route> */}
          <Layout>
            <Switch>
              <Route path="/home" exact component={Home}></Route>
              <Route path="/about-us" component={AboutUs}></Route>
              <Route path="/services/:id" component={Services}></Route>
              <Route path="/services" component={Services}></Route>
              <Route path="/pricing/:id" exact component={SinglePricing}></Route>
              <Route path="/pricing" component={Pricing}></Route>
              <Route path="/project/:id" component={SingleProject}></Route>
              <Route path="/project" component={Project}></Route>
              <Route path="/blog/:id" component={SingleNews}></Route>
              <Route path="/blog" component={Blog}></Route>
            </Switch>
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
