import axios from "axios";

const urlAPI = "https://prismamultimedia.com/admin";

const baseURL = "https://prismamultimedia.com/api"; // Base URL for API requests

// Function to make a GET request
const fetchData = async (endpoint, callback) => {
  try {
    const response = await axios.get(`${baseURL}/${endpoint}`);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
};

const directFetchData = async (endpoint, params) => {
  try {
    const response = await axios.get(`${baseURL}/${endpoint}`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const fetchImagesProduct = async (urlAPI, productTypeId, productId) => {
  try {
    const response = await axios.get(`${baseURL}/product/${productId}/images`);
    const imageData = response.data;

    if (imageData && Array.isArray(imageData.data) && imageData.data.length > 0) {
      return {
        productTypeId,
        imageUrl: imageData.data
      };
    }
  } catch (error) {
    console.error(`Error fetching product images for product ID ${productId}:`, error);
    return null;
  }
};

// Function to make a POST request
const postData = async (endpoint, data) => {
  try {
    const response = await axios.post(`${baseURL}/${endpoint}`, data);
    return response;
  } catch (error) {
    return error;
  }
};

// Define endpoints for different API requests
const endpoints = {
  landingpage: "landing-page",
  service: "service",
  banner: "banner",
  brand: "brand",
  portofolio: "portfolio",
  contact: "contact",
  about: "pages/who-we-are",
  home: "pages/home",
  blog: "blog",
  product:'product',
  productType:'product-type',
  benefit:'benefit',
  serviceBanner:'service/banner',
  project:'project',
  storyline:'storyline',
  menu:'menu',
  social:'social',
  integrationImg:'integration-image',
};

// Generate functions dynamically for each endpoint
const apiFunctions = {};
for (const key in endpoints) {
  if (endpoints.hasOwnProperty(key)) {
    const endpoint = endpoints[key];
    apiFunctions[key] = (callback) => fetchData(endpoint, callback);
  }
}

// Export API functions and baseURL
export { apiFunctions, urlAPI, postData, directFetchData, fetchImagesProduct };
