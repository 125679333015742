import { useEffect, useState, useRef } from "react";
import { apiFunctions, urlAPI } from "../../../services/api.services";
import { Link, NavLink } from "react-router-dom";
import { Logo } from "../../../assets/images";

const Navbar = () => {
  const [data, setData] = useState("");
  const [contactVal, setContactVal] = useState([]);
  const [menu, setMenu] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    apiFunctions.landingpage((data) => {
      setData(data.data);
    });
    apiFunctions.menu((data) => {
      setMenu(data.data);
    });
    apiFunctions.social((data) => {
      setContactVal(data.data);
    });
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event) => {
    // Cek jika klik terjadi di luar elemen sidebar
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    // Tambahkan event listener saat komponen mount
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Hapus event listener saat komponen unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="sticky bg-white shadow-lg w-full z-20 top-0 start-0">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src={urlAPI + data.landing_banner_logo}
            className="h-10 md:h-12 brightness-0"
            alt="logo"
          />
        </Link>

        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <a
            href={contactVal
              .filter((item) => item.name === "Whatsapp")
              .map((item) => item.url)}
            className="hidden lg:block border-[1px] border-darkColor bg-darkColor text-white hover:scale-105 py-2 px-3 w-full rounded-lg mr-2"
          >
            Contact
          </a>
          <a
            href="/admin"
            className="hidden lg:block border-[1px] border-darkColor hover:bg-darkColor py-2 px-3 w-full rounded-lg hover:text-white"
          >
            Login
          </a>

          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-sticky"
            aria-expanded={isSidebarOpen}
            onClick={toggleSidebar}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        <div
          className="items-center justify-between hidden w-full lg:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
            {menu &&
              menu.map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.url}
                    className={(isActive) =>
                      "block py-2 px-3 text-gray-900 md:p-0" +
                      (isActive ? " border-b-2 border-b-gray-900" : "")
                    }
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
        <div
          className={`${
            isSidebarOpen ? "fixed" : "hidden"
          } inset-0 bg-black bg-opacity-50 transition-opacity lg:hidden`}
          aria-hidden="true"
        >
          <div className="absolute inset-y-0 right-0 pl-10 max-w-[85%] flex sm:pl-16 outline-none">
            <div className="w-screen max-w-md">
              <div
                className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
                ref={sidebarRef}
              >
                <div className="px-4 py-3">
                  <div className="flex items-start justify-between">
                    <div className="h-7 flex items-center">
                      <button
                        onClick={toggleSidebar}
                        className="text-gray-500 hover:text-gray-900 transition ease-in-out duration-150"
                      >
                        <svg
                          className="h-6 w-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="px-8 pt-2 pb-3 space-y-1">
                  {menu &&
                    menu.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.url}
                        className={(isActive) =>
                          "block py-2 pr-3 text-gray-900" +
                          (isActive
                            ? " border-b-2 border-b-gray-900 w-fit"
                            : "")
                        }
                        onClick={() => setIsSidebarOpen(false)} // Menutup sidebar saat item menu diklik
                      >
                        {item.name}
                      </NavLink>
                    ))}
                </div>
                <div className="my-auto px-6">
                  <a
                    href={contactVal
                      .filter((item) => item.name === "Whatsapp")
                      .map((item) => item.url)}
                    className="block py-2 text-gray-900 md:w-3/4 m-auto"
                  >
                    <button className="bg-darkColor py-2 w-full rounded-lg text-white">
                      Contact
                    </button>
                  </a>
                  <a
                    href="/admin"
                    className="block py-2 text-gray-900 md:w-3/4 m-auto"
                  >
                    <button className="bg-darkColor py-2 w-full rounded-lg text-white">
                      Login
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
