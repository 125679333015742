import React, { useEffect, useState } from "react";
import { apiFunctions, urlAPI, postData } from "../../services/api.services";
import { FaCheckCircle } from "react-icons/fa";
import { Swiper2 } from "../../components/commons/swiper";

import Modal from "../../components/commons/modal"
import Marquee from "react-fast-marquee";

const Destinations = () => {
  const [homepageVal, setHomepage] = useState([]);
  const [serviceVal, setService] = useState([]);
  const [portoVal, setPorto] = useState([]);
  const [contactVal, setContact] = useState([]);
  const [brandVal, setBrand] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    apiFunctions.landingpage((data) => {
      setHomepage(data.data);
    });
    apiFunctions.service((data) => {
      setService(data.data);
    });
    apiFunctions.portofolio((data) => {
      setPorto(data.data);
    });
    apiFunctions.contact((data) => {
      setContact(data.data);
    });
    apiFunctions.brand((data) => {
      setBrand(data.data);
    });
  }, []);

  const [newsPost, setNewsPost] = useState({
    name: "",
    email: "",
    phone: "",
    message:"",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    let filteredValue = value;

    // If the input field is 'phone', apply filtering
    if (name === "phone") {
      filteredValue = value.replace(/[^0-9+]/g, ""); // Allow only numbers and '+'
    }

    // Update the state with the filtered value
    setNewsPost({
      ...newsPost,
      [name]: filteredValue,
    });
  };

  const handlePost = async (event) => {
    event.preventDefault();
    // console.log(newsPost, 'news ----1');

    await postData("customer", newsPost);
    setOpenModal(true);
    // console.log('Data posted successfully!');
  };

  return (
    <div>
      {/* {homepageVal.length >= 1 && */}
      <div>
        <div className="max-w-[1240px] mx-auto py-16 px-4 text-center">
          {/* belum */}
          <div className="bg-clip-text text-transparent bg-gradient-to-r from-[rgb(14,70,163)] to-[#031736]">
            <p className="uppercase pb-1 text-3xl md:text-8xl font-bold tracking-widest">
              {homepageVal.landing_service_title}
            </p>
            <h1 className="amiri-regular uppercase tracking-widest text-black text-xl md:text-5xl font-medium">
              {homepageVal.landing_service_subtitle}
            </h1>
          </div>
          <div className="max-w-[54rem] m-auto px-5 amiri-regular pt-10 md:py-10">
            <div
              dangerouslySetInnerHTML={{
                __html: homepageVal.landing_service_description,
              }}
            />
          </div>
          {serviceVal.length >= 1 && (
            <div>
              {serviceVal.map((item, index) => (
                <div
                  key={index}
                  className={`lg:flex md:pt-28 ${
                    index % 2 === 0 ? "" : "md:flex-row-reverse"
                  }`}
                >
                  <div className="pt-10 md:pt-0 md:w-5/6 lg:w-3/6 m-auto">
                    <img
                      className="md:img-layout2 mx-auto lg:h-96 w-full object-cover"
                      src={urlAPI + item.image_url}
                      alt=""
                    />
                  </div>
                  <div
                    className={`m-auto px-5 text-center lg:text-left md:w-5/6 lg:w-3/6 my-auto pt-5 lg:pt-0 bg-clip-text text-transparent bg-gradient-to-r from-[#0E46A3] to-[#031736]  ${
                      index % 2 === 0
                        ? ""
                        : "lg:text-right md:from-[#031736] md:to-[#0E46A3]"
                    }`}
                  >
                    <h1 className="uppercase tracking-widest text-xl md:text-4xl font-bold">
                      {item.name}
                    </h1>
                    <div
                      className="py-5 text-black"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="w-full h-96">
          <img
            className="w-full h-96 object-cover bg-darkColor/30"
            src={urlAPI + homepageVal.landing_brand_image}
            alt="brand_image"
          />
          <div
            className={`md:flex bg-darkColor/80 text-white p-4 md:p-20 relative h-96 w-full z-[1] -top-96`}
          >
            <div className="md:w-80 ml-auto text-justify my-auto lg:mr-20">
              <h1 className="text-2xl md:text-4xl font-medium text-center md:text-justify">
                {homepageVal.landing_brand_title}
              </h1>
              <div
                className="py-5"
                dangerouslySetInnerHTML={{
                  __html: homepageVal.landing_brand_caption,
                }}
              />
            </div>
            <div className="font-bold text-center md:text-left md:w-3/6 my-auto px-5">
              <h1 className="uppercase tracking-widest text-5xl md:text-9xl font-bold">
                {homepageVal.landing_brand_count}
              </h1>
              <p className="py-5 text-xl md:text-4xl">
                {homepageVal.landing_brand_subtitle}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full py-10 lg:py-20 text-center">
          {/* <Swiper2 /> */}
          <Marquee direction="right" speed={100} delay={5}>
            {brandVal &&
              brandVal.map((item)=>
                <div className="mx-4">
                  <img src={urlAPI+item.image_url} alt={item.name} className="lg:grayscale hover:grayscale-0 h-10 w-24 md:h-14 md:w-32 lg:w-44 object-contain m-auto"/>
                </div>
              )
            }
          </Marquee>
          <button className="mt-10 lg:mt-20 bg-birtua text-sm text-white px-5 lg:px-7 py-3 lg:py-4 rounded-lg"
            onClick={() => setShowAll(true)} >
            Show All
          </button>
        </div>
        <div className="bg-[#f5f5f5] text-center pb-10 md:pb-20">
          <h1 className="uppercase text-2xl md:text-4xl py-10 md:py-20">
            {homepageVal.landing_portfolio_title}
          </h1>
          <div className="flex flex-wrap lg:w-3/4 m-auto gap-8 gap-x-0">
            {portoVal.map((item, index) => (
              <div className="m-auto group/item lg:grayscale hover:grayscale-0">
                <img
                  src={urlAPI + item.image_url}
                  alt=""
                  className="md:h-80 md:w-80 object-cover"
                />
                <div className="invisible group-hover/item:visible flex absolute w-full h-full top-0 left-0 bg-darkColor/40 text-white">
                  <div className="m-auto text-2xl font-medium">{item.name}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="pt-10">
            <a
              href={homepageVal.landing_portfolio_button_link}
              className="hover:bg-darkColor hover:text-white bg-transparent text-darkColor py-2 px-5 rounded border border-darkColor"
            >
              {homepageVal.landing_portfolio_button_label}
            </a>
          </div>
        </div>
        <div className="text-center pb-10 md:pb-20">
          <h1 className="py-10 md:py-20 text-4xl">{homepageVal.landing_booking_title}</h1>
          <div className="md:flex w-11/12 lg:w-3/4 m-auto border rounded-lg bg-[#f5f5f5]">
            <div className="md:w-3/6 md:order-2 h-72 md:h-[29rem]">
              <img
                className="md:img-layout2 mx-auto h-full w-full object-cover rounded-t md:rounded-r-lg"
                src={urlAPI + contactVal.landing_contact_image}
                alt=""
              />
              <div className="relative w-full h-full bottom-72 md:bottom-[29rem] left-0 bg-darkColor/30 rounded-t md:rounded-r-lg"></div>
            </div>
            
            <div className="px-5 text-left md:w-3/6 m-auto pt-5 md:pt-0 md:order-1 bg-clip-text text-transparent bg-gradient-to-r from-[#0E46A3] to-[#031736] md:h-[29rem]">
              <form onSubmit={handlePost}>
                <div className="sm:col-span-4 text-black py-5 w-3/4 m-auto">
                  <div>
                    <div className="mx-auto sm:max-w-md">
                      <p className="text-sm my-1">Name</p>
                      <input
                        type="text"
                        id="fullname"
                        name="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Full Name"
                        value={newsPost.name}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <div className="mx-auto sm:max-w-md">
                      <p className="text-sm my-1">Email</p>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Email"
                        value={newsPost.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <div className="mx-auto sm:max-w-md">
                      <p className="text-sm my-1">Whatsapp Number</p>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Whatsapp number"
                        value={newsPost.phone}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mx-auto max-w-md">
                      <p className="text-sm my-1">Project</p>
                      <textarea
                        type="text"
                        id="details"
                        name="message"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Tell about your project"
                        value={newsPost.message}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className="pt-5 md:pt-10 text-center">
                    <button className="bg-transparent text-darkColor py-2 px-5 rounded border border-darkColor text-sm md:text-base hover:bg-darkColor hover:text-white">
                      {homepageVal.landing_booking_button_label}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="text-center w-56">
          <FaCheckCircle size={56} className="mx-auto text-green-500" />
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-semibold text-gray-800">
              Congratulations
            </h3>
            <p className="text-sm text-gray-500">Your data has been added!</p>
          </div>
          <div className="flex gap-4">
            <button
              className="border py-2 bg-darkColor text-white w-full rounded-lg"
              onClick={() => setOpenModal(false)}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
      <Modal open={showAll} onClose={()=>setShowAll(false)}>
        <div className="modalBrand">
        {brandVal &&
          brandVal.map((item)=>
            <div className="m-auto">
              <img src={urlAPI+item.image_url} alt={item.name} className="lg:grayscale hover:grayscale-0 h-10 w-24 md:h-14 md:w-36 lg:w-44 xl:w-48 object-contain m-auto"/>
            </div>
          )
        }
        </div>
      </Modal>
    </div>
  );
};

export default Destinations;
