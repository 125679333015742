import { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {
  IconClock,
  IconSecured,
  IconWheel,
  Portfolio,
} from "../../assets/images";
import { apiFunctions, fetchImagesProduct, urlAPI } from "../../services/api.services";
import { BiCart } from "react-icons/bi";
import { FaCartShopping } from "react-icons/fa6";
import axios from "axios";

const Index = () => {
  const [typeProduct, setType] = useState([]);
  const [contactVal, setContactVal] = useState([]);
  const [landing, setLanding] = useState("");
  const [benefit, setBenefit] = useState("");
  useEffect(() => {
    apiFunctions.social((data) => {
      setContactVal(data.data);
    });
    apiFunctions.productType((data) => {
      setType(data.data);
    });
    apiFunctions.benefit((data) => {
      setBenefit(data?.data);
    });
    apiFunctions.landingpage((data) => {
      setLanding(data?.data);
    });
  }, []);

  const [sortOrder, setSortOrder] = useState("asc"); 

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const sortedProducts = typeProduct.sort((a, b) => {
    if (sortOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
  
  return (
    <div id="pricing">
      <section id="lists-prices">
        <div class="bg-gray-100 pt-10 pb-20">
          <div class="p-4 mx-auto lg:max-w-7xl sm:max-w-full">
            <h2 class="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#0E46A3] to-[#031736] mb-12">Products of Prisma Multimedia</h2>
            <div className="flex justify-end mb-4 text-sm">
              <select value={sortOrder} onChange={handleSortOrderChange} className="bg-white border border-gray-300 rounded pr-4 pl-1 py-2 mr-2">
                <option value="asc">Sort A-Z</option>
                <option value="desc">Sort Z-A</option>
              </select>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-xl:gap-4 gap-6">
            {sortedProducts.map((item) => (
              <a href={`/pricing/${item.id}`} className="bg-white rounded-2xl p-5 cursor-pointer hover:-translate-y-2 transition-all relative" key={item.id}>
                <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute bottom-4 right-4">
                  <FaCartShopping className="fill-[#0E46A3] inline-block" />
                </div>
                <div className="h-[210px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-4">
                  <img src={urlAPI + item.image_url} alt={`Product ${item.id}`} className="h-full w-full object-cover rounded" />
                </div>
                <div>
                  <h3 className="text-lg font-extrabold text-gray-800">{item.name}</h3>
                  <div className="text-gray-600 text-sm mt-2" dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </a>
            ))}
              {/* <div class="bg-white rounded-2xl p-5 cursor-pointer hover:-translate-y-2 transition-all relative">
                <div
                  class="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute top-4 right-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18px" class="fill-gray-800 inline-block" viewBox="0 0 64 64">
                    <path
                      d="M45.5 4A18.53 18.53 0 0 0 32 9.86 18.5 18.5 0 0 0 0 22.5C0 40.92 29.71 59 31 59.71a2 2 0 0 0 2.06 0C34.29 59 64 40.92 64 22.5A18.52 18.52 0 0 0 45.5 4ZM32 55.64C26.83 52.34 4 36.92 4 22.5a14.5 14.5 0 0 1 26.36-8.33 2 2 0 0 0 3.27 0A14.5 14.5 0 0 1 60 22.5c0 14.41-22.83 29.83-28 33.14Z"
                      data-original="#000000"></path>
                  </svg>
                </div>
                <div class="w-11/12 h-[210px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-4">
                  <img src="https://readymadeui.com/images/product9.webp" alt="Product 1"
                    class="h-full w-full object-contain" />
                </div>
                <div>
                  <h3 class="text-lg font-extrabold text-gray-800">Sole Elegance</h3>
                  <p class="text-gray-600 text-sm mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  <h4 class="text-lg text-gray-800 font-bold mt-4">$10</h4>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>
      <section id="contact-pricing">
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 w-3/5 rounded-xl lg:px-10 py-5 lg:py-14 border-2 border-abumud">
            <div className="lg:flex flex-row justify-between items-center">
              <div className="basis-1/2 text-base md:text-3xl tracking-wider font-semibold text-center lg:text-left mb-3 lg:mb-0">
                {benefit?.text}
              </div>
              <a href={contactVal.filter(item => item.name === 'Whatsapp').map(item => item.url)}>
                <div className="text-white mx-auto lg:mx-0 w-1/2 lg:w-auto lg:px-10 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg">
                  <p className="text-center text-xs md:text-base">Contact Us</p>
                </div>
              </a>
            </div>
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto my-10">
          <div className="lg:flex flex-row justify-center items-center gap-5">
            {benefit?.data?.map((item,index)=>(
              <div className="basis-1/3 flex items-center gap-2 md:w-1/2 m-auto pb-3">
                <img src={urlAPI+item[`footer_image_${index + 1}`]} alt="wheel" className="w-12 h-12 object-cover"/>
                <p className="font-base">
                  {item[`footer_caption_${index + 1}`]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
