import React, { useEffect, useState } from "react";
import { apiFunctions, urlAPI } from "../../services/api.services";
import {
  BannerHomePoly,
  IconClock,
  IconSecured,
  IconWheel,
  LeftPoly,
  RightPoly,
} from "../../assets/images";

import { Link } from "react-router-dom";
import { ProductSlider } from "../../components/commons/swiper";

function Service() {
  const [data, setData] = useState("");
  const [contactVal, setContactVal] = useState([]);
  const [benefit, setBenefit] = useState("");
  const [service, setService] = useState("");

  useEffect(() => {
    apiFunctions.home((data) => {
      setData(data);
    });
    apiFunctions.social((data) => {
      setContactVal(data.data);
    });
    apiFunctions.benefit((data) => {
      setBenefit(data?.data);
    });
    apiFunctions.serviceBanner((data) => {
      setService(data?.data);
      // console.log(data?.data,'serviceBanner')
    });
  }, []);

  return (
    <div id="home">
      <div className="w-[100vw]">
        <section className="max-w-screen-xl mx-auto lg:flex items-center text-center lg:text-start justify-between py-10 lg:py-40">
          <div className="w-10/12 m-auto lg:w-2/5 lg:order-2">
            <h1 className="lg:hidden xl:text-5xl text-2xl xl:leading-tight mb-5 font-semibold">
              {service?.banner_title}
            </h1>
            <img
              src={BannerHomePoly}
              className="hidden lg:block absolute top-0 right-0 -z-10"
              alt="arthome"
              style={{ maxHeight: "40rem" }}
            />
            <video 
              className="bg-white border-2 p-2 lg:p-4 mx-auto rounded-3xl w-full object-cover"
              src={urlAPI + service?.banner_video} autoPlay loop 
              width="700px" height="500px" alt="vid"/>

            
          </div>
          <div className="w-4/5 m-auto h-full my-7 lg:w-2/5 lg:order-1">
            <h1 className="hidden lg:block xl:text-5xl sm:text-3xl xl:leading-tight mb-5 font-semibold">
              {service?.banner_title}
            </h1>
            <div
              className="text-sm leading-normal"
              dangerouslySetInnerHTML={{
                __html: service?.banner_caption,
              }}
            />
            <button className="mt-8 bg-birtua text-sm text-white px-7 py-4 rounded-lg">
              <Link to={service?.banner_button_link} />
              {service?.banner_button_label}
            </button>
          </div>
        </section>

        <section id="timelines" className="w-[90vw] mx-auto">
          {data?.services && (
            <div>
              {data?.services.map((item, index) => (
                <div
                  key={index}
                  className={`w-full md:flex md:pt-28 ${
                    index % 2 === 0 ? "md:flex-row-reverse" : ""
                  }`}
                >
                  <div className="relative pt-10 md:pt-0 md:w-3/6 z-10 my-auto">
                    <img
                      src={index % 2 === 0 ? RightPoly : LeftPoly}
                      className={`absolute ${
                        index % 2 === 0
                          ? "hidden md:block md:-right-10 md:min-h-40 lg:min-h-[30rem] lg:-right-[72px] -top-24"
                          : "hidden md:block md:min-h-40 lg:min-h-[30rem] -left-24 -top-24"
                      } -z-10`}
                      alt="arthome"
                    />
                    <video
                      className="md:img-layout2 bg-white border-2 p-7 mx-auto lg:h-96 rounded-3xl w-auto object-cover"
                      src={urlAPI + item.video_url}
                      autoPlay loop
                      alt="vid"
                    />
                    {/* <img
                      className="md:img-layout2 bg-white border-2 p-7 mx-auto md:h-96 rounded-3xl w-full object-cover"
                      src={urlAPI + item.image_url}
                      alt="gif"
                    /> */}
                  </div>
                  <div
                    className={`px-5 text-center md:text-left md:w-3/6 my-auto pt-5 md:pt-0`}
                  >
                    <h4 className="uppercase text-sm lg:text-base font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#0E46A3] to-[#031736]">
                      {item.subtitle}
                    </h4>
                    <div className="text-2xl md:text-3xl lg:text-5xl leading-normal tracking-wide font-semibold">
                      {item.name} {/* Stay on schedule with time management tools. */}
                      <hr
                        className={`h-1 w-48 mt-2 mx-auto md:mx-0 lg:mt-5 bg-gradient-to-r from-[#0E46A3] to-[#031736] border-t`}
                      />
                    </div>
                    <div
                      className="py-5 text-black text-sm lg:text-base"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>

        <section id="views">
          <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto text-center py-20 lg:py-32">
            <h4 className="uppercase text-birmud-prisma font-semibold pb-1 tracking-wide">
              {data?.product_type?.text?.product_title}
            </h4>
            <h1 className="text-3xl lg:text-5xl tracking-wide font-semibold pb-5">
              {data?.product_type?.text?.product_subtitle}
            </h1>
            <ProductSlider product={data?.product_type?.data} />
            
            <a href="/pricing">
              <button className="text-white px-5 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg mt-5 lg:mt-20">
                See More
              </button>
            </a>
          </div>
        </section>

      </div>

      <section id="contact-pricing">
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 w-3/5 rounded-xl lg:px-10 py-5 lg:py-14 border-2 border-abumud">
            <div className="lg:flex flex-row justify-between items-center">
              <div className="basis-1/2 text-base md:text-3xl tracking-wider font-semibold text-center lg:text-left mb-3 lg:mb-0">
                {benefit?.text}
              </div>
              <a href={contactVal.filter(item => item.name === 'Whatsapp').map(item => item.url)}>
                <div className="text-white mx-auto lg:mx-0 w-1/2 lg:w-auto lg:px-10 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg">
                  <p className="text-center text-xs md:text-base">Contact Us</p>
                </div>
              </a>
            </div>
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto my-10">
          <div className="lg:flex flex-row justify-center items-center gap-5">
            {benefit?.data?.map((item,index)=>(
              <div className="basis-1/3 flex items-center gap-2 md:w-1/2 m-auto pb-3">
                <img src={urlAPI+item[`footer_image_${index + 1}`]} alt="wheel" className="w-12 h-12 object-cover"/>
                <p className="font-base">
                  {item[`footer_caption_${index + 1}`]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Service;
