import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import NavLinks from "../Navbar/NavLinks";
import { BiSearch } from 'react-icons/bi';
import { AiOutlineShop } from 'react-icons/ai';
import { apiFunctions, urlAPI, directFetchData } from '../../../services/api.services';

import Modal from "../../commons/modal"
const NavbarLP = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavLinksHovered, setIsNavLinksHovered] = useState(false);

  const [contactVal, setContact] = useState([]);

  useEffect(() => {
    apiFunctions.landingpage((data) => {
      setContact(data.data);
    });

    const changeColor = () => {
      if (window.scrollY >= 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', changeColor);

    return () => {
      window.removeEventListener('scroll', changeColor);
    };
  }, []);

  const handleNavLinksHover = (isHovered) => {
    setIsNavLinksHovered(isHovered);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useHistory();

  const handleSearch = async (event) => {
    event.preventDefault();
    try {
      const searchData = await directFetchData('collection', { search: searchTerm });
      // console.log('Filtered Data:', searchData.data);

      setOpenModal(false);
      navigate(`/collection?search=${encodeURIComponent(searchTerm)}`, {
        state: { searchResult: searchData.data },
      });
    } catch (error) {
      console.error('Failed to fetch filtered data:', error);
    }
  };

  return (
    <nav className={`fixed w-full h-auto z-20 ${isScrolled || isNavLinksHovered ? 'bg-darkColor' : ''}`} >
      <div className="flex items-center font-medium justify-between md:p-1">
        
        <a href="/" className='pt-0'><img src={urlAPI+contactVal.landing_banner_logo} alt="logo" className="md:cursor-pointer h-8 md:h-14 pl-2 md:pl-4 w-full object-cover md:block" /></a>
        <div className="flex md:w-[516px] justify-end md:p-0 p-2 my-auto">
          <ul className='flex text-white'>
            <li className='p-1 md:p-5'>
              <a href="/home" className='flex' target='blank'>
                <AiOutlineShop size={20} />
                <p className='ml-2 hidden md:block'>Home</p>
              </a>
            </li>
          </ul>
        </div>
        {/* Mobile nav */}
        <ul
          className={`
        md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 pl-4
        duration-500 z-10 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <a href="/"><img src={urlAPI+contactVal.landing_banner_logo} alt="" className='h-20 top-2 object-cover relative left-0'/></a>
          <li className='pl-3'>
            <Link to="/gallery" className="pt-0 inline-block">
              Gallery
            </Link>
          </li>
          <NavLinks />
        </ul>
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="text-center w-72 md:w-96">
          <p>Search Our Collection!</p>
          <form onSubmit={handleSearch}>
            <div className="relative mx-auto text-gray-600">
              <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="border-2 border-gray-300 bg-white h-10 px-5 w-full mt-4 rounded-lg text-sm focus:outline-none"
                autocomplete="search" id="search" name="search" placeholder="Search"/>
              <button type="submit" className="absolute right-0 bottom-0 border-0 hover:bg-transparent">
                <BiSearch className='text-gray-600 h-4 w-4 fill-current'/>
              </button>
            </div>
          </form>
          
        </div>
      </Modal>
    </nav>
  );
};

export default NavbarLP;
