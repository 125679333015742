import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiFunctions, urlAPI } from "../../services/api.services";

import { ClientSlider } from "../../components/commons/swiper";
import ellipse1 from '../../assets/images/Ellipse1.png'
import ellipse2 from '../../assets/images/Ellipse2.png'


function Blog() {
  const [landing, setLanding] = useState("");
  const [benefit, setBenefit] = useState("");
  const [blog, setBlog] = useState([])
  const [contactVal, setContactVal] = useState([])
  useEffect(() => {
    apiFunctions.landingpage((data) => {
      setLanding(data?.data);
    });
    apiFunctions.benefit((data) => {
      setBenefit(data?.data);
    });
    apiFunctions.blog((data) => {
      setBlog(data.data);
    });
    apiFunctions.social((data) => {
      setContactVal(data.data);
    });
  }, []);

  const renderTruncatedHTML = (htmlString, maxLength) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    let textContent = tempDiv.textContent || tempDiv.innerText || '';
    if (textContent.length > maxLength) {
      textContent = textContent.substr(0, maxLength) + '...';
    }
    tempDiv.innerHTML = textContent;
    return tempDiv.innerHTML;
  };

  return (
    <div id="project">
      {blog.length >= 1 &&
      <>
        <div className='w-full h-screen relative'>
          <img
            className='w-full h-full object-cover'
            src={urlAPI+blog[blog.length - 1].image_url}
          />
          <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
          <div className="absolute w-full h-full top-0 left-0">
            <img src={ellipse1} alt="" />
          </div>
          <div className="absolute w-full h-full top-0 left-0">
            <img src={ellipse2} alt="" className="w-full h-screen" />
          </div>
          <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4 amiri-regular'>
            <h1 className='lg:text-5xl text-2xl tracking-widest font-semibold my-5 text-white"'>{blog[blog.length - 1].title}</h1>
            <div className='text-sm tracking-wider text-[#f1f1f1] leading-normal' dangerouslySetInnerHTML={{ __html: renderTruncatedHTML(blog[blog.length - 1].description, 100) }} />
            <a href={`/blog/${blog[blog.length - 1].id}`}>
              <button className="mt-8 bg-transparent md:text-xl text-white border-white border-2 px-3 md:px-7 py-2 rounded-lg">
                Read More
              </button>
            </a>
          </div>
          
        </div>

        
        <section id="project-detail" className="bg-abu py-10 lg:py-40">
          <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto">
            <h1 className="text-4xl md:text-6xl font-medium text-center mb-5 text-birtua tracking-wider">
              All Articles
            </h1>
            <div>
              {blog.slice(0, -1).map((item, index) => (
                <div
                  key={index}
                  className={`lg:flex md:pt-28 ${
                    index % 2 === 0 ? "" : "md:flex-row-reverse"
                  }`}
                >
                  <div className="pt-10 md:pt-0 md:w-5/6 lg:w-3/6 m-auto">
                    <img
                      className="md:img-layout2 mx-auto lg:h-96 w-full object-cover rounded-xl"
                      src={urlAPI + item.image_url}
                      alt=""
                    />
                  </div>
                  <div
                    className={`m-auto px-5 text-center lg:text-left md:w-5/6 lg:w-3/6 my-auto pt-5 lg:pt-0 bg-clip-text text-transparent bg-gradient-to-r from-[#0E46A3] to-[#031736]  ${
                      index % 2 === 0
                        ? ""
                        : "lg:text-right md:from-[#031736] md:to-[#0E46A3]"
                    }`}
                  >
                    <h1 className="uppercase tracking-widest text-xl md:text-4xl font-bold">
                      {item.title}
                    </h1>
                    <div
                      className="py-5 text-black"
                      dangerouslySetInnerHTML={{ __html: renderTruncatedHTML(item.description,100) }}
                    />
                    <div className="pb-3 text-birmud">
                      <Link to={`/blog/${item.id}`} className="uppercase underline underline-offset-4 hover:tracking-wider">read more</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        
      </>
      }

      <section id="contact-pricing">
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 w-3/5 rounded-xl lg:px-10 py-5 lg:py-14 border-2 border-abumud">
            <div className="lg:flex flex-row justify-between items-center">
              <div className="basis-1/2 text-base md:text-3xl tracking-wider font-semibold text-center lg:text-left mb-3 lg:mb-0">
                {benefit?.text}
              </div>
              <a href={contactVal.filter(item => item.name === 'Whatsapp').map(item => item.url)}>
                <div className="text-white mx-auto lg:mx-0 w-1/2 lg:w-auto lg:px-10 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg">
                  <p className="text-center text-xs md:text-base">Contact Us</p>
                </div>
              </a>
            </div>
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto my-10">
          <div className="lg:flex flex-row justify-center items-center gap-5">
            {benefit?.data?.map((item,index)=>(
              <div className="basis-1/3 flex items-center gap-2 md:w-1/2 m-auto pb-3">
                <img src={urlAPI+item[`footer_image_${index + 1}`]} alt="wheel" className="w-12 h-12 object-cover"/>
                <p className="font-base">
                  {item[`footer_caption_${index + 1}`]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blog;
