import React, { useEffect, useState } from "react";
import { apiFunctions, urlAPI } from "../../services/api.services";
import {
  BannerHomePoly,
  FullPoly,
  IconFilePen,
  LeftPoly,
  RightPoly,
} from "../../assets/images";

import { ArrowRightAlt } from "@mui/icons-material";
import {
  BlogSlider,
  BrandSlider,
  IntegrationsSlider,
  ProductSlider,
  ReviewSlider,
  SwiperMarquee,
} from "../../components/commons/swiper";

import Marquee from 'react-fast-marquee';

function Home() {
  const [data, setData] = useState("");
  const [landing, setLanding] = useState("");
  const [benefit, setBenefit] = useState("");
  const [storyline, setStoryline] = useState("");
  const [contactVal, setContactVal] = useState([]);
  const [integrationImg, setintegrationImg] = useState([]);
  const [tabService, setTabService] = useState(0);
  useEffect(() => {
    apiFunctions.social((data) => {
      setContactVal(data.data);
    });
    apiFunctions.home((data) => {
      setData(data);
    });
    apiFunctions.landingpage((data) => {
      setLanding(data?.data);
    });
    apiFunctions.benefit((data) => {
      setBenefit(data?.data);
    });
    apiFunctions.storyline((data) => {
      setStoryline(data?.data);
    });
    apiFunctions.integrationImg((data) => {
      setintegrationImg(data.data);
    });
  }, []);
  
  const handleTab = (type) => {
    switch (type) {
      case "communicate":
        setTabService(0);
        break;
      case "registration":
        setTabService(1);
        break;
      case "proof":
        setTabService(2);
        break;
      default:
        setTabService(0);
        break;
    }
  };

  return (
    <div id="home">
      <div className="w-[100vw]">
        <section className="max-w-screen-xl mx-auto lg:flex items-center text-center lg:text-start justify-between py-10 lg:py-40">
          <div className="w-10/12 m-auto lg:w-2/5 lg:order-2">
            <h1 className="lg:hidden xl:text-5xl text-2xl xl:leading-tight mb-5 font-semibold">
              {data?.banner?.banner_title}
            </h1>
            <img
              src={BannerHomePoly}
              className="hidden lg:block absolute top-0 right-0 -z-10"
              alt="arthome"
              style={{ maxHeight: "40rem" }}
            />
            <video 
              className="bg-white border-2 p-2 lg:p-4 mx-auto rounded-3xl w-full object-cover"
              src={urlAPI + data?.banner?.banner_video} autoPlay loop 
              width="700px" height="500px" alt="vid"/>

            
          </div>
          <div className="w-4/5 m-auto h-full my-7 lg:w-2/5 lg:order-1">
            <h1 className="hidden lg:block xl:text-5xl sm:text-3xl xl:leading-tight mb-5 font-semibold">
              {data?.banner?.banner_title}
            </h1>
            <div
              className="text-sm leading-normal"
              dangerouslySetInnerHTML={{
                __html: data?.banner?.banner_caption,
              }}
            />
            <a href={data?.banner?.banner_button_link}>
              <button className="mt-8 bg-birtua text-sm text-white px-7 py-4 rounded-lg">
                {data?.banner?.banner_button_label}
              </button>
            </a>
          </div>
        </section>

        <section id="teams">
          <div className="relative flex py-5 items-center">
            <div className="flex-grow border-t border-gray-400"></div>
            <span className="flex-shrink mx-4 text-gray-400 text-xs md:text-base">
              {data?.brands?.title}
            </span>
            <div className="flex-grow border-t border-gray-400"></div>
          </div>
          <div className="my-10">
            <Marquee direction="right" speed={100} delay={5}>
              {data?.brands &&
                data?.brands?.data.map((item)=>
                  <div className="mx-4">
                    <img src={urlAPI+item.image_url} alt={item.name} className="lg:grayscale hover:grayscale-0 h-10 w-24 md:h-14 md:w-32 lg:w-44 object-contain m-auto"/>
                  </div>
                )
              }
            </Marquee>
          </div>
          <hr className="max-w-96 mx-auto bg-gray-400 border-t my-20"></hr>
        </section>

        <section id="timelines" className="w-[90vw] mx-auto">
          {storyline && (
            <div>
              {storyline.map((item, index) => (
                <div
                  key={index}
                  className={`w-full md:flex md:pt-28 ${
                    index % 2 === 0 ? "md:flex-row-reverse" : ""
                  }`}
                >
                  <div className="relative pt-10 md:pt-0 md:w-3/6 z-10 my-auto">
                    <img
                      src={index % 2 === 0 ? RightPoly : LeftPoly}
                      className={`absolute ${
                        index % 2 === 0
                          ? "hidden md:block md:-right-10 md:min-h-40 lg:min-h-[30rem] lg:-right-[72px] -top-24"
                          : "hidden md:block md:min-h-40 lg:min-h-[30rem] -left-24 -top-24"
                      } -z-10`}
                      alt="arthome"
                    />
                    <video
                      className="md:img-layout2 bg-white border-2 p-7 mx-auto lg:h-96 rounded-3xl w-auto object-cover"
                      src={urlAPI + item.video_url}
                      autoPlay loop
                      alt="vid"
                    />
                    {/* <img
                      className="md:img-layout2 bg-white border-2 p-7 mx-auto md:h-96 rounded-3xl w-full object-cover"
                      src={urlAPI + item.image_url}
                      alt="gif"
                    /> */}
                  </div>
                  <div
                    className={`px-5 text-center md:text-left md:w-3/6 my-auto pt-5 md:pt-0`}
                  >
                    <h4 className="uppercase text-sm lg:text-base font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#0E46A3] to-[#031736]">
                      {item.subtitle}
                    </h4>
                    <div className="text-2xl md:text-3xl lg:text-5xl leading-normal tracking-wide font-semibold">
                      {item.name} {/* Stay on schedule with time management tools. */}
                      <hr
                        className={`h-1 w-1/2 mt-2 mx-auto md:mx-0 lg:mt-5 bg-gradient-to-r from-[#0E46A3] to-[#031736] border-t`}
                      />
                    </div>
                    <div
                      className="py-5 text-black text-sm lg:text-base"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>

        <section id="templates">
          <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto py-20 lg:py-20 lg:pt-32">
            <h4 className="text-sm md:text-base uppercase text-birmud-prisma font-semibold pb-2 lg:pb-5 tracking-wide">
              {data?.projects?.text?.project_title}
            </h4>
            <h1 className="text-2xl md:text-3xl lg:text-5xl tracking-wide font-semibold pb-5 md:pb-10">
              {data?.projects?.text?.project_subtitle}
            </h1>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 align-center gap-10">
              {data?.projects?.data.slice(0,3).map((item,index) => (
                <a href={`/project/${item.id}`} className="border-l-2 border-birmud py-3 pl-6 shadow-lg rounded-r-xl flex">
                  <div className="">
                    {item.image_url === null ? (
                      <img
                        src={IconFilePen}
                        alt="file"
                        className="bg-birmud-prisma rounded-xl p-3"
                      />
                    ) : (
                      <img
                        src={urlAPI+item.image_url}
                        alt="file"
                        className="rounded-xl h-32 w-32 object-cover"
                      />
                    )}
                  </div>
                  <div className="pl-3 flex flex-col">
                    <div className="text-xl lg:text-3xl font-semibold">
                      {item.name}
                    </div>
                    <p className="text-birmud-prisma text-sm lg:text-base font-semibold mt-auto">
                      View Project <ArrowRightAlt />
                    </p>
                  </div>
                </a>
              ))}
            </div>
            <a href="/project">
              <button className="text-white px-5 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg mt-20">
                See More Project
              </button>
            </a>
          </div>
        </section>

        <section id="views">
          <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto py-10">
            <h4 className="text-sm md:text-base uppercase text-birmud-prisma font-semibold pb-2 lg:pb-5 tracking-wide">
              {data?.product_type?.text?.product_title}
            </h4>
            <h1 className="text-2xl md:text-3xl lg:text-5xl tracking-wide font-semibold pb-5 lg:pb-10">
              {data?.product_type?.text?.product_subtitle}
            </h1>
            <p className="text-sm tracking-wide text-darkColor pb-10">
              {data?.product_type?.text?.product_caption}
            </p>
            <ProductSlider product={data?.product_type?.data} />
            
          </div>
        </section>

        <div className="lg:py-20">
          <section className="max-w-80 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg mx-auto">
            {data?.tab?.data && (
              <div className="border-l-4 shadow-lg border-birmud px-4 lg:px-10 py-5 lg:py-10 lg:mb-5">
                <div className="lg:flex flex-row justify-center items-center gap-5">
                  <div className="basis-4/5 text-2xl lg:text-4xl tracking-wider font-semibold mb-3">
                    {data?.tab?.title}
                  </div>
                  
                </div>
              </div>
            )}
          </section>
          <section id="tab" className="w-[90vw] mx-auto">
            {data?.tab && (
              <div>
                {data?.tab?.data.map((item, index) => (
                  <div
                    key={index}
                    className={`w-full md:flex md:pt-28 ${
                      index % 2 === 0 ? "" : "md:flex-row-reverse"
                    }`}
                  >
                    <div className="relative pt-10 md:pt-0 md:w-3/6 z-10 my-auto">
                      <img
                        src={index % 2 === 0 ? LeftPoly : RightPoly}
                        className={`absolute ${
                          index % 2 === 0
                            ? "hidden md:block md:min-h-40 lg:min-h-[30rem] -left-24 -top-24"
                            : "hidden md:block md:-right-10 md:min-h-40 lg:min-h-[30rem] lg:-right-[51px] xl:-right-[72px] -top-24"
                        } -z-10`}
                        alt="arthome"
                      />
                      <video
                        className="md:img-layout2 bg-white border-2 p-7 mx-auto lg:h-96 rounded-3xl w-auto object-cover"
                        src={urlAPI + item.home_tab_video}
                        autoPlay loop
                        alt="vid"
                      />
                    </div>
                    <div
                      className={`px-5 text-center md:text-left md:w-3/6 my-auto pt-5 md:pt-0`}
                    >
                      <h4 className="uppercase text-sm lg:text-base font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#0E46A3] to-[#031736]">
                        {item.home_tab_section}
                      </h4>
                      <div className="text-2xl md:text-3xl lg:text-5xl leading-normal tracking-wide font-semibold">
                        {item.home_tab_title} 
                        <hr
                          className={`h-1 w-1/2 mt-2 mx-auto md:mx-0 lg:mt-5 bg-gradient-to-r from-[#0E46A3] to-[#031736] border-t`}
                        />
                      </div>
                      <div
                        className="py-5 text-black text-sm lg:text-base"
                        dangerouslySetInnerHTML={{ __html: item.home_tab_caption }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </section>
        </div>

        <section id="reviews">
          <div className="text-center max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto py-20 lg:py-32 pb-0">
            <h4 className="uppercase text-birmud-prisma font-semibold pb-1 tracking-wide">
              {data?.testimonials?.subtitle}
            </h4>
            <h1 className="text-3xl lg:text-5xl tracking-wide font-semibold pb-5">
              {data?.testimonials?.title}
            </h1>
            <ReviewSlider data={data?.testimonials?.data}/>
          </div>
        </section>

        <section id="views">
          <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto py-10 text-center">
            <h4 className="uppercase text-birmud-prisma font-semibold pb-1 tracking-wide">
              {data?.blogs?.subtitle}
            </h4>
            <h1 className="text-3xl lg:text-5xl tracking-wide font-semibold pb-5">
              {data?.blogs?.title}
            </h1>
            <BlogSlider data={data?.blogs?.data}/>
            
            <a href="/blog">
              <button className="text-white px-5 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg mt-10 lg:mt-20">
                See More Articles
              </button>
            </a>
          </div>
        </section>

        <section id="integrations">
          <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto py-10 lg:py-32 text-center">
            <h4 className="uppercase text-birmud-prisma font-semibold pb-1 tracking-wide">
              {data?.integrations?.text?.integration_title}
            </h4>
            <h1 className="text-3xl lg:text-5xl tracking-wide font-semibold pb-5">
              {data?.integrations?.text?.integration_subtitle}
            </h1>
            {/* <IntegrationsSlider data={data?.integrations} /> */}
            <Marquee direction="right" speed={100} delay={5}>
              {integrationImg &&
                integrationImg.map((item)=>
                  <div className="mx-4">
                    <img src={urlAPI+item.image_url} alt={item.name} className="lg:grayscale hover:grayscale-0 h-14 w-24 md:h-24 lg:h-28 md:w-32 lg:w-48 xl:w-52 object-contain m-auto"/>
                  </div>
                )
              }
            </Marquee>
            <p className="text-sm py-4">
              {data?.integrations?.text?.integration_caption}
            </p>
          </div>
        </section>
      </div>
      {/*end container*/}

      <section id="contact-pricing">
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 w-3/5 rounded-xl lg:px-10 py-5 lg:py-14 border-2 border-abumud">
            <div className="lg:flex flex-row justify-between items-center">
              <div className="basis-1/2 text-base md:text-3xl tracking-wider font-semibold text-center lg:text-left mb-3 lg:mb-0">
                {benefit?.text}
              </div>
              <a href={contactVal.filter(item => item.name === 'Whatsapp').map(item => item.url)}>
                <div className="text-white mx-auto lg:mx-0 w-1/2 lg:w-auto lg:px-10 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg">
                  <p className="text-center text-xs md:text-base">Contact Us</p>
                </div>
              </a>
            </div>
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-xl mx-auto my-10">
          <div className="lg:flex flex-row justify-center items-center gap-5">
            {benefit?.data?.map((item,index)=>(
              <div className="basis-1/3 flex items-center gap-2 md:w-1/2 m-auto pb-3">
                <img src={urlAPI+item[`footer_image_${index + 1}`]} alt="wheel" className="w-12 h-12 object-cover"/>
                <p className="font-base">
                  {item[`footer_caption_${index + 1}`]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;

export const Communication = ({ tab, data }) => {
  return (
    <div className="md:flex md:pt-28">
      {data && (
        <>
          <div className="relative pt-10 md:pt-0 md:w-1/2 z-10">
            <img
              src={FullPoly}
              className="hidden md:block absolute -top-20 lg:-top-28 -left-32 -z-10 lg:min-h-[40rem]"
              alt="arthome"
            />
            <video
              className="md:img-layout2 border-2 p-7 mx-auto lg:h-96 rounded-3xl w-auto object-cover shadow-lg"
              src={urlAPI + data?.tab?.data[tab]?.home_tab_video}
              autoPlay loop
              alt="vid"
            />
          </div>
          <div className={`px-5 md:text-left md:w-3/6 my-auto pt-5 md:pt-0 text-center`}>
            <div className="text-2xl lg:text-5xl leading-normal tracking-wide font-semibold">
              {data?.tab?.data[tab]?.home_tab_title}
              <div className={`flex`}>
                <hr
                  className={`h-1 w-48 mt-5 bg-gradient-to-r from-[#0E46A3] to-[#031736] border-t mx-auto md:mx-0`}
                />
              </div>
            </div>
            <div
              className="py-5 text-black"
              dangerouslySetInnerHTML={{
                __html: data?.tab?.data[tab]?.home_tab_caption,
              }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};
