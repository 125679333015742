import React from "react";
import Destinations from "./Destinations.jsx";
import Footer from "../../components/layouts/Footer.jsx";
import Hero from "./Hero.jsx";
import Navbar from "../../components/layouts/NavbarLP/NavbarLP.jsx";
import FloatButton from "../../components/commons/buttons/BtnFloat.jsx";

const LandingPage = () => {
  return (
    <div>
      <FloatButton />
      <Navbar />
      <Hero />
      <Destinations />
      <Footer />
    </div>
  );
};

export default LandingPage;
