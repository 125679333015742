import React, { useEffect, useState } from "react";
import { apiFunctions, urlAPI } from "../../services/api.services";

import { useParams } from "react-router-dom/cjs/react-router-dom";


function SingleProject() {
  const { id } = useParams();

  const [benefit, setBenefit] = useState("");
  const [project, setBlog] = useState([])
  const [contactVal, setContactVal] = useState([])
  useEffect(() => {
    apiFunctions.benefit((data) => {
      setBenefit(data?.data);
    });
    apiFunctions.project((data) => {
      setBlog(data.data);
    });
    apiFunctions.social((data) => {
      setContactVal(data.data);
    });
  }, []);

  const formatDateLong = (dateString) => {
    const date = new Date(dateString);
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div id="project">
      {project.length >= 1 &&
      <>
        {project.filter((item) => item.id === parseInt(id)).map((item, index) => (
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-20 lg:mb-32">
          <div class="max-w-3xl mx-auto">
            <div class="py-8">
                <h1 class="text-3xl font-bold mb-2">{item.name}</h1>
                <p class="text-gray-500 text-sm">Published on <time datetime={item.created_at}>{formatDateLong(item.created_at)}</time></p>
            </div>
            <img src={urlAPI+item.image_url} alt="Featured image" class="w-full h-auto mb-8 rounded-lg"></img>
            <div class="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto">
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          </div>
        </div>
        ))}
      </>
      }

      <section id="contact-pricing">
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 w-3/5 rounded-xl lg:px-10 py-5 lg:py-14 border-2 border-abumud">
            <div className="lg:flex flex-row justify-between items-center">
              <div className="basis-1/2 text-base md:text-3xl tracking-wider font-semibold text-center lg:text-left mb-3 lg:mb-0">
                {benefit?.text}
              </div>
              <a href={contactVal.filter(item => item.name === 'Whatsapp').map(item => item.url)}>
                <div className="text-white mx-auto lg:mx-0 w-1/2 lg:w-auto lg:px-10 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg">
                  <p className="text-center text-xs md:text-base">Contact Us</p>
                </div>
              </a>
            </div>
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto my-10">
          <div className="lg:flex flex-row justify-center items-center gap-5">
            {benefit?.data?.map((item,index)=>(
              <div className="basis-1/3 flex items-center gap-2 md:w-1/2 m-auto pb-3">
                <img src={urlAPI+item[`footer_image_${index + 1}`]} alt="wheel" className="w-12 h-12 object-cover"/>
                <p className="font-base">
                  {item[`footer_caption_${index + 1}`]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleProject;