import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";
import { apiFunctions, urlAPI } from "../../../services/api.services";

const NavLinks = ({ handleNavLinksHover }) => {
  const [categoryVal, setCategory] = useState([]);

  // useEffect(()=>{
  //   apiFunctions.landingpage((data) => {
  //     setCategory(data.data);
  //   });
  // }, [])

  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");

  const handleMouseEnter = () => {
    if (handleNavLinksHover) {
      handleNavLinksHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (handleNavLinksHover) {
      handleNavLinksHover(false);
    }
  };

  const renderTruncatedHTML = (htmlString, maxLength) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    let textContent = tempDiv.textContent || tempDiv.innerText || "";
    if (textContent.length > maxLength) {
      textContent = textContent.substr(0, maxLength) + "...";
    }
    tempDiv.innerHTML = textContent;
    return tempDiv.innerHTML;
  };

  return (
    <>
      {links.map((link) => (
        <div
          className="z-10 pb-4 md:pb-0"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="px-3 text-left md:cursor-pointer group">
            <p
              className="flex justify-between items-center md:pr-0 pr-5 group"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
              }}
            >
              {link.name}
              <span className="text-xl md:hidden inline">
                <ion-icon
                  name={`${
                    heading === link.name ? "chevron-up" : "chevron-down"
                  }`}
                ></ion-icon>
              </span>
              <span className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <ion-icon name="chevron-down"></ion-icon>
              </span>
            </p>
            {link.submenu && (
              <div>
                <div className="absolute z-10 top-10 hidden group-hover:md:block hover:md:block w-full right-0">
                  <div className="pb-3 pt-4">
                    <div
                      className="w-4 h-4 left-3 absolute 
                    mt-1 bg-white rotate-45"
                    ></div>
                  </div>
                  <div
                    className={`bg-white p-5 grid grid-cols-3 gap-6 ${
                      link.name === "Our Collection"
                        ? "overflow-y-auto h-96"
                        : ""
                    }`}
                  >
                    {link.name === "Our Collection"
                      ? categoryVal.map((mysublinks, index) => (
                          <a
                            href={`/collection/${mysublinks.id}`}
                            className="flex"
                          >
                            {mysublinks.image_url && (
                              <img
                                src={urlAPI + mysublinks.image_url}
                                alt={mysublinks.title}
                                className="h-32 w-32 object-cover mt-8 mx-auto"
                              />
                            )}
                            <div className="p-2 pt-0">
                              <p
                                className={`text-lg font-semibold ${
                                  mysublinks.description &&
                                  "relative right-[8.5rem]"
                                }`}
                              >
                                {mysublinks.title}
                              </p>
                              <p
                                className="normal-case text-justify"
                                dangerouslySetInnerHTML={{
                                  __html: renderTruncatedHTML(
                                    mysublinks.description,
                                    100
                                  ),
                                }}
                              />
                            </div>
                          </a>
                        ))
                      : link.sublinks.map((mysublinks) => (
                          <div className="flex">
                            {mysublinks.Image && (
                              <img
                                src={mysublinks.Image}
                                alt={mysublinks.Head}
                                className="h-40 w-40 object-cover mt-8 mx-auto"
                              />
                            )}
                            <div className="p-2 pt-0">
                              <p
                                className={`text-lg font-semibold ${
                                  mysublinks.description &&
                                  "relative right-[8.5rem]"
                                }`}
                              >
                                {mysublinks.Head}
                              </p>
                              <p className="normal-case text-justify">
                                {mysublinks.description}
                              </p>
                              {mysublinks.sublink.map((slink) => (
                                <li className="text-sm text-gray-600 my-2.5 p-2">
                                  <Link
                                    to={slink.link}
                                    className="hover:text-primary"
                                  >
                                    {slink.name}
                                  </Link>
                                </li>
                              ))}
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.name === "Our Collection"
              ? categoryVal.map((slinks, index) => (
                  <div>
                    <div>
                      <p
                        onClick={() =>
                          subHeading !== slinks.title
                            ? setSubHeading(slinks.title)
                            : setSubHeading("")
                        }
                        className="py-4 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center"
                      >
                        {slinks.title}

                        {/* <span className="text-xl md:mt-1 md:ml-2 inline">
                        <ion-icon
                          name={`${
                            subHeading === slinks.title
                              ? "chevron-up"
                              : "chevron-down"
                          }`}
                        ></ion-icon>
                      </span> */}
                      </p>
                      <div
                        className={`${
                          subHeading === slinks.title ? "md:hidden" : "hidden"
                        }`}
                      >
                        {/* {slinks.sublink.map((slink) => (
                        <li className="py-3 pl-14">
                          <Link to={slink.link}>{slink.name}</Link>
                        </li>
                      ))} */}
                      </div>
                    </div>
                  </div>
                ))
              : link.sublinks.map((slinks) => (
                  <div>
                    <div>
                      <p
                        onClick={() =>
                          subHeading !== slinks.Head
                            ? setSubHeading(slinks.Head)
                            : setSubHeading("")
                        }
                        className="py-4 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center"
                      >
                        {slinks.Head}

                        <span className="text-xl md:mt-1 md:ml-2 inline">
                          <ion-icon
                            name={`${
                              subHeading === slinks.Head
                                ? "chevron-up"
                                : "chevron-down"
                            }`}
                          ></ion-icon>
                        </span>
                      </p>
                      <div
                        className={`${
                          subHeading === slinks.Head ? "md:hidden" : "hidden"
                        }`}
                      >
                        {slinks.sublink.map((slink) => (
                          <li className="py-3 pl-14">
                            <Link to={slink.link}>{slink.name}</Link>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}

            {/* {link.sublinks.map((slinks) => (
              <div>
                <div>
                  <p
                    onClick={() =>
                      subHeading !== slinks.Head
                        ? setSubHeading(slinks.Head)
                        : setSubHeading("")
                    }
                    className="py-4 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center md:pr-0 pr-5"
                  >
                    {slinks.Head}

                    <span className="text-xl md:mt-1 md:ml-2 inline">
                      <ion-icon
                        name={`${
                          subHeading === slinks.Head
                            ? "chevron-up"
                            : "chevron-down"
                        }`}
                      ></ion-icon>
                    </span>
                  </p>
                  <div
                    className={`${
                      subHeading === slinks.Head ? "md:hidden" : "hidden"
                    }`}
                  >
                    {slinks.sublink.map((slink) => (
                      <li className="py-3 pl-14">
                        <Link to={slink.link}>{slink.name}</Link>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
