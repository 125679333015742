import React, { useEffect, useState } from "react";
import { apiFunctions, urlAPI } from "../../services/api.services";
import ellipse1 from '../../assets/images/Ellipse1.png'
import ellipse2 from '../../assets/images/Ellipse2.png'


const Hero = () => {
  const [homepageVal, setHomepage] = useState([]);

  useEffect(() => {
    apiFunctions.landingpage((data) => {
      setHomepage(data.data);
    });
  }, []);

  return (
  <>
    <div className='w-full h-screen relative bg-darkColor'>
      <img
        className='w-full h-full object-cover'
        src={urlAPI + homepageVal.landing_banner_image}
      />
      <div className="absolute w-full h-full top-0 left-0">
        <img src={ellipse1} alt="" />
      </div>
      <div className="absolute w-full h-full top-0 left-0">
        <img src={ellipse2} alt="" className="w-full h-screen" />
      </div>
      <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
      <div className='absolute top-0 w-full h-full flex flex-col justify-center text-right text-white p-4 amiri-regular'>
        <h1 className='text-2xl md:text-4xl lg:text-7xl font-semibold uppercase lg:w-1/2 ml-auto'>{homepageVal.landing_banner_title}</h1>
        <div className="max-w-60 md:max-w-md lg:max-w-xl ml-auto">
          <div className='text-xs md:text-base my-10' dangerouslySetInnerHTML={{ __html: homepageVal.landing_banner_caption }} />
        </div>
        <div className="pt-2 md:pt-10 flex gap-5 text-sm md:text-base">
          <div className="ml-auto">
            <a
              href={homepageVal.landing_banner_button_link_1}
              className="bg-transparent text-white py-3 px-4 md:py-4 md:px-10 rounded border shadow-md border-[#0E46A3] hover:bg-[#0E46A3]"
            >
              {homepageVal.landing_banner_button_label_1}
            </a>
          </div>
          <div>
            <a
              href={homepageVal.landing_banner_button_link_2}
              className="text-white py-3 px-4 md:py-4 md:px-10 rounded border-white shadow-md bg-[#0E46A3] hover:bg-white hover:text-darkColor"
            >
              {homepageVal.landing_banner_button_label_2}
            </a>
          </div>
        </div>
      </div>
      
    </div>
  </>
  );
};

export default Hero;
