import React, { useEffect, useState } from "react";

import { apiFunctions, urlAPI } from "../../services/api.services";

const Footer = () => {
  const [contactVal, setContact] = useState([]);
  const [social, setSocial] = useState([]);
  const [dataLPVal, setdataLP] = useState([]);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    apiFunctions.social((data) => {
      setSocial(data.data);
    });
    apiFunctions.contact((data) => {
      setContact(data.data);
    });
    apiFunctions.landingpage((data) => {
      setdataLP(data.data);
    });
    apiFunctions.menu((data) => {
      setMenu(data.data);
    });
  }, []);

  return (
    <div>
      <div className="w-full bg-darkColor relative text-white mx-auto bottom-0 flex flex-col p-14 pt-0">
        <a href="/"><img src={urlAPI+dataLPVal.landing_banner_logo} alt="" className='lg:hidden mx-auto h-20 object-cover mt-10'/></a>
        <p className="alegreya-sans-light text-center pt-10 md:py-10">
          2024 &copy; Prisma Multimedia
        </p>
        <div className="lg:flex text-center justify-between items-center">
          <div className="">
            <a href="/"><img src={urlAPI+dataLPVal.landing_banner_logo} alt="" className='hidden lg:block h-20 top-2 object-cover relative left-0'/></a>
          
            <div className="flex w-full lg:justify-normal gap-4 md:gap-8 md:w-auto mt-10 justify-center">
              {social && 
              social.map((item) =>
              <>
                <a href={item.url}>
                  <img src={urlAPI+item.icon_url} className="h-14" />
                </a>
              </>
              )}
            </div>
          </div>
          <div className="text-center">
            <p className="md:text-xl py-5">EXPLORE</p>
            <div className="flex gap-40 py-2 justify-center">
              <div className="text-left">
                {menu && menu.slice(0,3).map(item =>
                <div className="mb-2">
                  <a href={item.url}>{item.name}</a>
                </div>
                )}
              </div>
              <div className="text-left">
                {menu && menu.slice(3,6).map(item =>
                <div className="mb-2">
                  <a href={item.url}>{item.name}</a>
                </div>
                )}
              </div>
            </div>
          </div>
          <div className="lg:text-right mt-10 lg:mt-auto">
            <p className="md:text-xl py-5">PT Prisma Multimedia Solusi</p>
            <div
              className="py-2 font-normal"
              dangerouslySetInnerHTML={{
                __html: contactVal.landing_contact_address,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
