import React, { useEffect, useState }  from 'react';
import {Box,Fab,Link} from '@mui/material';
import { FaWhatsappSquare } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";


import { apiFunctions } from '../../../services/api.services';

export default function FloatingActionButtons() {
  const [contactVal, setContact] = useState([]);

  useEffect(()=>{
    apiFunctions.social((data) => {
      setContact(data.data);
    });
  }, [])
  return (
    <div className='fixed z-10 bottom-2 md:bottom-4 right-1 md:right-2'>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Link href={contactVal.filter(item => item.name === 'Whatsapp').map(item => item.url)} target="_blank" rel="noopener">
                <Fab variant="extended" class='border bg-white rounded text-black hover:bg-darkColor hover:text-white  px-2 py-2'>
                    <div className='flex'>
                    <RiWhatsappFill className="h-8 w-8 mr-1 text-green-500"/>
                    {/* <WhatsAppIcon color="success"  sx={{ mr: 1 }} /> */}
                    <p className='my-auto'>Need help?</p>
                    </div>
                </Fab>
            </Link>
        </Box>
    </div>
  );
}